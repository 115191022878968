import React from 'react';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// mock
import { api } from 'src/api';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';

// ----------------------------------------------------------------------

export default function BetHouses() {

  const [clientBetHouses, setClientBetHouses] = useState([]);
  
  const [betHouses, setBetHouses] = useState([]);

  const [selectBetHouse, setSelectBetHouse] = useState({
    visible: false,
    nameHouse: "Selecione uma casa"
  });

  const [betHouseModal, setBetHouseModal] = useState(
    { betHouseId: "-1", wallet: 0 }
  )

  const [open, setOpen] = React.useState(false);

  const [visibleInfo, setVisibleInfo] = useState({
    visible: false,
    message: ""
  });

  const newBetHouse = () => {

    api.get('/bet/retrieveAllBetHouse')
          .then((response) => {
            setBetHouses(response.data);
    });

    setBetHouseModal({ 
      betHouseId: "-1", 
      wallet: 0 
    });

    setSelectBetHouse({
      visible: false,
      nameHouse: "Selecione uma casa"
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: 'betHouseName', headerName: 'Casa de Aposta', flex: 1,},
    { field: 'wallet', headerName: 'Valor da banca', flex: 1,},
    { field: 'edit',
      headerName: 'Ações',
      renderCell: (cellValues) =>
        <ButtonGroup variant="text" aria-label="text button group">
          <Button
            startIcon={<Edit />}
            onClick={(event) => {
              handleEditClick(event, cellValues.row)
            }}
          />
          <Button
            startIcon={<Delete />}
            onClick={(event) => {
              handleDeleteClick(event, cellValues.row)
            }}
          />
        </ButtonGroup>,
    },
  ];

  useEffect(() => {
    
    getBetHouse();

  }, []);

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Casas de Apostas
          </Typography>
          <Button variant="contained" onClick={newBetHouse} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nova Casa
          </Button>
        </Stack>

        {
            clientBetHouses.length === 0
            ? <strong>Nenhum registro cadastrado</strong>  
            :
            <Card>
                <div style={{ height: 250, width: '100%' }}>
                  <DataGrid
                    autoHeight
                    rows={clientBetHouses}
                    columns={columns}
                  />
                </div>
            </Card>
        }
        
      </Container>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Cadastro de Banca</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: 400 }}>
            <Stack spacing={4}>
              <Select
                label="Casa" 
                name="house"
                disabled = {selectBetHouse.visible}
                value={betHouseModal.betHouseId}
                onChange={e => setBetHouseModal(
                      {
                          ...betHouseModal,
                          betHouseId: e.target.value
                      }
                  )} >
                  {
                    betHouses.map(
                        betHouseLoop => {
                            return <MenuItem key={betHouseLoop.id} value={betHouseLoop.id}>{betHouseLoop.name}</MenuItem>
                        }
                  )}
              </Select>

              <TextField
                label="Valor da Banca" 
                name="value"
                type="number"
                value={betHouseModal.wallet}
                onChange={e => setBetHouseModal(
                  {
                      ...betHouseModal,
                      wallet: e.target.value
                  }
              )}/>
            </Stack>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
          <Button onClick={saveBetHouse}>Salvar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={visibleInfo.visible} onClose={() => setVisibleInfo(false)} >
        <DialogContent>
          {visibleInfo.message}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisibleInfo(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );

  function getBetHouse() {

    api.get('/user/retrieveClientBetHouse').then((response) => {
      setClientBetHouses(response.data);
    });
  
  }
  
  function handleEditClick(event, clientBetHouseEdit) {

    event.stopPropagation();
    if(clientBetHouseEdit.betHouseId !== "-1"){
      api.get('/bet/retrieveAllBetHouse')
          .then((response) => {
            setBetHouses(response.data);
      });
      setBetHouseModal({
        betHouseId: clientBetHouseEdit.betHouseId, 
        wallet: clientBetHouseEdit.wallet
      });
      setSelectBetHouse({
        visible: true,
        nameHouse: clientBetHouseEdit.betHouseName
      });
      setOpen(true);
    }
  }
  
  function handleDeleteClick(event, clientBetHouseDelete) {
    event.stopPropagation();
    api.post('/user/deleteClientBetHouse/' + clientBetHouseDelete.id)
        .then((response) => {
          if(response.data === 'ok'){
            setVisibleInfo({
              visible: true,
              message: "Casa removida com sucesso!"
            });
            getBetHouse()
          }
          else{
            setVisibleInfo({
              visible: true,
              message: "Não foi possivel remover, pois existem apostas relacionadas a esta casa!"
            });
          }
    });
  }

  function saveBetHouse() {

    if(betHouseModal.betHouseId !== "-1"){
      api.post('/user/saveClientBetHouse', betHouseModal)
          .then((response) => {
            setVisibleInfo({
              visible: true,
              message: "Casa salva com sucesso!"
            });
            getBetHouse()
      });

      setOpen(false);
    }
  }
}