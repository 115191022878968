import React from 'react';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// mock
import { api } from 'src/api';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';

// ----------------------------------------------------------------------

export default function Users() {

  const [clientBets, setClientBets] = useState([]);
  

  const [userModal, setUserModal] = useState(
    { name: "", email: "", password: "" }
  )

  const [open, setOpen] = React.useState(false);

  const [visibleInfo, setVisibleInfo] = useState({
    visible: false,
    message: ""
  });

  const newUser = () => {

    setUserModal({ 
      name: "", 
      email: "",
      password: ""
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'Id', flex: 1,},
    { field: 'name', headerName: 'Nome', flex: 1,},
    { field: 'username', headerName: 'Email', flex: 1,},
    { field: 'phone', headerName: 'Telephone', flex: 1,},
    { field: 'edit',
      headerName: 'Ações',
      renderCell: (cellValues) =>
        <ButtonGroup variant="text" aria-label="text button group">
          <Button
            startIcon={<Edit />}
            onClick={(event) => {
              handleEditClick(event, cellValues.row)
            }}
          />
          <Button
            startIcon={<Delete />}
            onClick={(event) => {
              handleDeleteClick(event, cellValues.row)
            }}
          />
        </ButtonGroup>,
    },
  ];

  useEffect(() => {
    
    getUsers();

  }, []);

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuários
          </Typography>
          <Button variant="contained" onClick={newUser} startIcon={<Iconify icon="eva:plus-fill" />}>
            Usuário
          </Button>
        </Stack>

        {
            clientBets.length === 0
            ? <strong>Nenhum registro cadastrado</strong>  
            :
            <Card>
                <div style={{ height: 250, width: '100%' }}>
                  <DataGrid
                    autoHeight
                    rows={clientBets}
                    columns={columns}
                  />
                </div>
            </Card>
        }
        
      </Container>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Cadastro de Clientes</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: 400 }}>
            <Stack spacing={4}>
              <TextField
                label="Nome" 
                name="name"
                type="text"
                value={userModal.name}
                onChange={e => setUserModal(
                  {
                      ...userModal,
                      name: e.target.value
                  }
              )}/>

              <TextField
                label="Email" 
                name="email"
                type="text"
                value={userModal.email}
                onChange={e => setUserModal(
                  {
                      ...userModal,
                      email: e.target.value
                  }
              )}/>

              <TextField
                label="Senha" 
                name="password"
                type="password"
                value={userModal.password}
                onChange={e => setUserModal(
                  {
                      ...userModal,
                      password: e.target.value
                  }
              )}/>

            </Stack>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
          <Button onClick={saveUser}>Salvar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={visibleInfo.visible} onClose={() => setVisibleInfo(false)} >
        <DialogContent>
          {visibleInfo.message}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisibleInfo(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );

  function getUsers() {

    api.get('/admin/listUsers').then((response) => {
      setClientBets(response.data);
    }); 
  
  }

  function saveUser() {

    api.post('/user/register', userModal).then((response) => {

      setVisibleInfo({
        visible: true,
        message: response.data
      });

      getUsers();
      handleClose();
    }); 
  
  }
  
  function handleEditClick(event, clientBetHouseEdit) {

/*     event.stopPropagation();
    if(clientBetHouseEdit.betHouseId !== "-1"){
      api.get('/bet/retrieveAllBetHouse')
          .then((response) => {
            setBetHouses(response.data);
      });
      setBetHouseModal({
        betHouseId: clientBetHouseEdit.betHouseId, 
        wallet: clientBetHouseEdit.wallet
      });
      setSelectBetHouse({
        visible: true,
        nameHouse: clientBetHouseEdit.betHouseName
      });
      setOpen(true);
    } */
  }
  
  function handleDeleteClick(event, clientBetHouseDelete) {
   /*  event.stopPropagation();
    api.delete('/bet/deleteBet/' + clientBetHouseDelete.id)
        .then((response) => {
          if(response.data === 'ok'){
            setVisibleInfo({
              visible: true,
              message: "Aposta removida com sucesso!"
            });
            getBets()
          }
          else{
            setVisibleInfo({
              visible: true,
              message: "Não foi possivel remover esta aposta! Tente novamente mais tarde."
            });
          }
    }); */
  }
}