import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.sporthubpro.cloud',
});

api.interceptors.request.use((config) => {
  const localToken = localStorage.getItem('@ListApp:userToken') != null
                      ? JSON.parse(localStorage.getItem('@ListApp:userToken')) : '{}';
  config.headers.Authorization =  `${localToken.token}`;
  return config;
}); 

export { api };