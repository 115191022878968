import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import BetHouses from './pages/BetHouses';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import { isAuthenticated } from './Auth';
import Users from './pages/Users';
import Admins from './pages/Admins';

// ----------------------------------------------------------------------

const PrivateRoute = ({ children }) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default function Router() {
  
  return useRoutes([
    {
      path: '/dashboard',
      element:  <PrivateRoute>
                  <DashboardLayout />
                </PrivateRoute>,
      children: [
        { path: 'home', element: <DashboardApp /> },
        { path: 'users', element: <Users /> },
        { path: 'admins', element: <Admins /> },
        { path: 'bethouses', element: <BetHouses /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <Login />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
