import { useState } from 'react';
// @mui
import { Alert, FormControl, Link, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/api';

export default function LoginForm() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null)

  async function saveUser(user) {
    localStorage.setItem('@ListApp:userToken', JSON.stringify(user));
    localStorage.setItem('@ListApp:userTokenDate', new Date());
  }

  async function signIn() {

    if ( username.length === 0 || password.length === 0 ){
      setErrorMessage("Informe seu email e senha!");
      return;
    }

    try {
      const credentials = {
        email:username,
        pass:password
      }

      const response = await api.post('/auth/admin', credentials)
  
      const user = response.data
  
      await saveUser(user)
  
      window.location.href = "/dashboard/home";
      
    } catch (error) {
      setErrorMessage("Email ou senha incorretos!");
    }
  };

  return (
    <FormControl>
      <Stack spacing={3}>
        <TextField
          label="Email" 
          name="email" 
          value={username}
          onChange={username => 
            setUsername(username.target.value)
          } />

        <TextField
          label="Senha" 
          name="password"
          type='password'
          value={password}
          onChange={password => 
            setPassword(password.target.value)
          }
        />
      </Stack>

      {!!errorMessage && 
        <Stack justifyContent="space-between" spacing={3}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      }

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      </Stack>

      <LoadingButton type="submit" fullWidth size="large" variant="contained" onClick={signIn}>
        Login
      </LoadingButton>
    </FormControl>
  );
}
